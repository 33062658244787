<template>
  <div class="express-send px-4">
    <div class="row">
      <div class="col-12 col-sm-3">
        <v-text-field label="Адрес эл. почты *"
                      v-model="email"
                      class="font-xs"
                      dense
                      hide-details
                      @input="onInput('email')"
                      @blur="$v.email.$touch()"
                      :error-messages="emailErrors"
                      outlined />
      </div>
      <div class="col-12 col-sm-3">
        <v-select label="Выберите тест *"
                  v-model="test" :items="tests"
                  class="font-xs"
                  dense
                  hide-details
                  item-text="text" item-value="value"
                  outlined />
      </div>
      <div class="col-12 col-sm-3">
        <v-text-field label="Введите должность *"
                      v-model="position"
                      class="font-xs"
                      dense
                      hide-details
                      @input="onInput('position')"
                      outlined />
      </div>
      <div class="col-12 col-sm-3 text-right">
        <v-btn class="btn-disabled-primary font-xs px-10 py-5" color="primary" disabled>Отправить тест</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { email, maxLength, required } from 'vuelidate/lib/validators';
import { MESSAGES } from '@/common/constants/errorMessages';
import { EMAIL } from '@/common/constants/validations';

export default {
  name: 'ExpressSendTest',
  validations: {
    email: { required, email, maxLength: maxLength(EMAIL.MAX_LENGTH) },
  },
  data: () => ({
    email: '',
    position: '',
    test: null,
    tests: [
      { text: 'тест1', value: 'null' },
      { text: 'тест2', value: '11111' },
      { text: 'тест3', value: '22222' },
    ],
    errors: [],
  }),
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.email.email) errors.push(MESSAGES.INCORRECT_EMAIL);
      if (!this.$v.email.maxLength) errors.push(MESSAGES.MAX_LENGTH(EMAIL.MAX_LENGTH));

      return this.errors.find((err) => err[0] === 'email')?.[1] ?? errors;
    },
  },
  methods: {
    onInput(field) {
      this.$v[field].$touch();
      if (this.errors.length) this.clearError();
    },
  },
};
</script>

<style scoped lang="scss">
.express-send {
  background: #FFFFFF;
  border-radius: 5px;
  display: flex;
  height: 100%;
  padding: 1rem 0;
}
</style>
