<template>
  <v-dialog v-model="dialogDeleteShow" max-width="600px">
    <v-card class="pa-8 pa-sm-16">
      <h2>Удалить?</h2>
      <div class="mt-6">
        Вы уверены, что хотите удалить кандидата?
        <br>
        Действие не может быть отменено.
      </div>
      <div class="mt-12 d-flex justify-center">
        <v-btn @click="dialogDeleteShow = false" class="px-10"
               large outlined text>Отмена</v-btn>
        <v-btn @click="deleteCandidateConfirm" class="ml-4 px-10"
               color="error" :loading="loading.delete" large>Удалить</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import candidateEditItemMixin from '@/components/candidates/candidateEditItemMixin';

export default {
  name: 'CandidateDeleteModal',
  mixins: [candidateEditItemMixin],
};
</script>
